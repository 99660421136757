/* prism-nba-theme.css */
code[class*="language-"],
pre[class*="language-"] {
  color: #ffffff;
  background: none;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #6a737d;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #6a737d;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
    overflow-x: auto;
  }
}

/* Customized NBA color scheme */

pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  /* Remove the background color here */
}

.token.keyword {
  color: #e31837; /* NBA red */
}

.token.string {
  color: #1d428a; /* NBA green */
}

.token.number,
.token.operator,
.token.punctuation {
  color: #ffffff;
}

.token.comment {
  color: #777;
}
