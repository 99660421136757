body {
    margin: 0;
    padding: 0;
  }
  
  .gradient-canvas {
    width: 100vw;
    height: 100vh;
    --gradient-color-1: #6ec3f4;
    --gradient-color-2: #3a3aff;
    --gradient-color-3: #ff61ab;
    --gradient-color-4: #e63946;
  }
